import axios from 'axios';
import {Activities, PhyicalActivityList, thisDay, AllActivity, endDate } from "../Pages/Main";
// Create an instance of axios with the base URL
export const api = axios.create({
  baseURL: 'https://orders.baleenmedia.com/API/Wellness',
});

// Fetching all the data parallely
export const FetchTimeDisciplineAndMeasurement = async ( updateFewTimeDiscipline, updateAvgFewTimeDiscpline, SelectedDate,updateTotalMeasurementWorkout, updateTotalMeasurementWeight, updateBarData, updateTotalTimeDiscipline, updateWorkoutOverall, updateWorkout, selectedMember, updateTeamDiscipline, updateTotalTeamDiscipline) => {
  try {
    const [ fewDaysDiscipline, avgFewDaysDiscipline, totalMeasurementWorkout, totalMeasurementWeight, teamDiscipline, totalTeamDiscipline, chartData, totalTimeDiscipline, overallWorkout, fewDaysWorkout] = await Promise.all([
      fetchTimeDisciplineBar(selectedMember, SelectedDate, endDate),
      fetchPastDaysTimeDiscipline(selectedMember, SelectedDate, endDate),
      fetchTotalMeasurement(selectedMember, 'Workout', endDate),
      fetchTotalMeasurement(selectedMember, 'Weight', endDate),
      fetchTeamDiscipline(SelectedDate, endDate),
      fetchTeamDiscipline('2023-02-25', endDate),
      fetchTimeDisciplineBar(selectedMember, '2023-02-25', endDate),
      fetchTotalTimeDiscipline(selectedMember, endDate),
      fetchWorkoutKcals(selectedMember, '2023-02-25', endDate),
      fetchWorkoutKcals(selectedMember, SelectedDate)
    ]);

    updateTotalTimeDiscipline(totalTimeDiscipline);
    updateAvgFewTimeDiscpline(avgFewDaysDiscipline);
    updateFewTimeDiscipline(fewDaysDiscipline);
    updateTotalMeasurementWorkout(totalMeasurementWorkout)
    updateTotalMeasurementWeight(totalMeasurementWeight)
    updateBarData(chartData)
    updateWorkoutOverall(overallWorkout)
    updateWorkout(fewDaysWorkout)
    updateTeamDiscipline(teamDiscipline)
    updateTotalTeamDiscipline(totalTeamDiscipline)
  } catch (error) {
    console.error('Error fetching data', error);
  }
};

// Fetching all the data parallely
const FetchAllDetailsAPI = async (updatePostedTime, updateactivityData,updatePhysicalActivityData,  updateWaterLtrs, updateAfresh, updateTeamMembers, selectedMember, updateTotalTeamMembers) => {
  try {
    const [postedTime, activityData, physcialActivityData, waterLtrs, AfreshScoops, teamMembers, totalTeamMembers] = await Promise.all([
      fetchPostedTime(selectedMember),
      fetchActivityData(selectedMember, 'Activity'),
      fetchActivityData(selectedMember, 'physical'),
      fetchAmountofWaterAfresh(selectedMember, 'Water'),
      fetchAmountofWaterAfresh(selectedMember, 'Afresh'),
      fetchCoachTeamMembers(localStorage.getItem('user')),
      fetchCoachInactiveTeamMembers(localStorage.getItem('user'))
      
    ]);

    updatePostedTime(postedTime); // Update the state with the postedTime value
    updateactivityData(activityData);
    updatePhysicalActivityData(physcialActivityData);
    updateWaterLtrs(waterLtrs)
    updateAfresh(AfreshScoops)
    updateTeamMembers(teamMembers)
    updateTotalTeamMembers(totalTeamMembers)
  } catch (error) {
    console.error('Error fetching data', error);
  }
};

export const fetchAmountofWaterAfresh = async (userName, ActivityName) => {
  let quantity = '';
  try {
    const response = await api.get(`/fetchAmountOfWaterAfresh.php`,{
      params:{
          JsonUserName: userName,
          JsonActivity: ActivityName,
          JsonDays: thisDay
      },
    });
    quantity = response.data;
    return quantity;
  } catch (error) {
    console.error('Error fetching data', error);
  }
}

//Setting Button Color
export const fetchActivityData = async (userName, ActivityType) => 
{
    let postedItem = []
    let postedPhysicalItem = []
    try 
    {
        if(ActivityType === 'Activity'){
            const promises = Activities.map(
                async (element) => 
                {
                const response = await api.get('/GetActivityDetails.php/get', {
                params: {
                    JsonUserName: userName,
                    JsonActivity: element,
                    JsonNow: thisDay
                },
                });
                return response.data === 'Posted' ? element : null;
                }
            );
            const results = await Promise.all(promises);
            postedItem = results.filter((result) => result !== null);
            return postedItem;
        }else{  
            const promises = PhyicalActivityList.map(
                async (element) => 
                {
                const response = await api.get('/GetPhysicalActivity.php/get', {
                params: {
                    JsonUserName: userName,
                    JsonPhysicalActivity: element,
                    JsonNow: thisDay
                },
                });
                return response.data === 'Posted' ? element : null;
                }
            );
            const results = await Promise.all(promises);
            postedPhysicalItem = results.filter((result) => result !== null);
            return postedPhysicalItem;
        }
    } 
    catch (error) 
    {
        console.error(error);
    }
};

export const fetchCoachTeamMembers = async (userName) => {
  let teamMembers = []; // Declare the teamMember array locally

  try {
      const response = await api.get('/GetCoachTeamMembers.php/', {
        params: {
          JsonCoachName: userName,
        },
      });
    const results = await Promise.all(response.data);
    teamMembers = [...new Set(results.flat())]; // Remove duplicates using Set and flat the array
    teamMembers.unshift(userName)
  } catch (error) {
    console.error('Error fetching data', error);
  }

  return teamMembers; // Return the updated teamMember
};

export const fetchReportTeamMembers = async (userName, page, limit) => {
  let teamMembers = []; // Declare the teamMember array locally

  try {
      const response = await api.get('/ReportTeamMembers.php/', {
        params: {
          JsonCoachName: userName,
          page: page,
          limit: limit
        },
        timeout: 3000
      });
    const results = await Promise.all(response.data);
    teamMembers = [...new Set(results.flat())]; // Remove duplicates using Set and flat the array
    if(page === 0){
      teamMembers.unshift(userName)
    }
  } catch (error) {
    console.error('Error fetching data', error);
  }

  return teamMembers; // Return the updated teamMember
};

export const fetchTotalMembersCount = async(userName) => {
  let teamCount = 0;

  try{
    const response = await api.get('/GetTotalMembersCount.php',{
      params:{
        JsonUserName: userName
      }
    });
    teamCount = await response.data;
  } catch (error){
    console.error('Error fetching data', error)
  }
  return teamCount;
}

export const fetchTodaysWeightData = async(userName) => {
  let weightData = 0;

  try{
    const response = await api.get('/TodaysWeightData.php',{
      params:{
        JsonUserName: userName
      }
    });
    weightData = response.data;
    console.log(response.data)
  } catch (error){
    console.error('Error fetching data', error)
  }
  return weightData;
}

export const fetchInitialHeightData = async(userName) => {
  let HeightData = 0;

  try{
    const response = await api.get('/GetInitialHeight.php',{
      params:{
        JsonUserName: userName
      }
    });
    HeightData = response.data;
    console.log(response.data)
  } catch (error){
    console.error('Error fetching data', error)
  }
  return HeightData;
}

export const fetchInitialWeightData = async(userName) => {
  let InitialWeightData = 0;

  try{
    const response = await api.get('/GetInitialWeight.php',{
      params:{
        JsonUserName: userName
      }
    });
    InitialWeightData = response.data;
    console.log(response.data)
  } catch (error){
    console.error('Error fetching data', error)
  }
  return InitialWeightData;
}

export const fetchTargetWeightData = async(userName) => {
  let TargetWeight = 0;

  try{
    const response = await api.get('/GetTargetWeight.php',{
      params:{
        JsonUserName: userName
      }
    });
    TargetWeight = response.data;
    console.log(response.data)
  } catch (error){
    console.error('Error fetching data', error)
  }
  return TargetWeight;
}


export const fetchLastAvailableWeightData = async(userName) => {
  let LastAvailableWeight = 0;

  try{
    const response = await api.get('/GetLastAvailableWeight.php',{
      params:{
        JsonUserName: userName
      }
    });
    LastAvailableWeight = response.data;
    console.log(response.data)
  } catch (error){
    console.error('Error fetching data', error)
  }
  return LastAvailableWeight;
}

export const fetchWeightReducedData = async(userName) => {
  let WeightReduced = 0;

  try{
    const response = await api.get('/GetWeightReduced.php',{
      params:{
        JsonUserName: userName
      }
    });
    WeightReduced = response.data;
    console.log(response.data)
  } catch (error){
    console.error('Error fetching data', error)
  }
  return WeightReduced;
}

export const fetchNeedToAchieveData = async(userName) => {
  let NeedToAchieve = 0;

  try{
    const response = await api.get('/GetNeedToAchieve.php',{
      params:{
        JsonUserName: userName
      }
    });
    NeedToAchieve = response.data;
    console.log(response.data)
  } catch (error){
    console.error('Error fetching data', error)
  }
  return NeedToAchieve;
}


export const fetchCoachInactiveTeamMembers = async (userName) => {
  let teamMembers = []; // Declare the teamMember array locally

  try {
      const response = await api.get('/GetCoachTeamMembers.php/', {
        params: {
          JsonCoachName: userName,
          JsonCountBoolean: userName
        },
      });
    const results = await Promise.all(response.data);
    teamMembers = [...new Set(results.flat())]; // Remove duplicates using Set and flat the array
    // teamMembers.unshift(userName)
  } catch (error) {
    console.error('Error fetching data', error);
  }

  return teamMembers; // Return the updated teamMember
};

export const fetchLastMeasurement = async (userName, activityName) => {
  try {
    const response = await api.get(`/GetLastMeasurement.php`, {
      params: {
        JsonUserName: userName,
        JsonActivity: activityName
      },
    });
    const measurement = parseFloat(response.data);
    return measurement;
  } catch (error) {
    console.error('Error fetching data', error);
    return null; // Return null or handle the error as needed
  }
}

export const fetchPostedTime = async (userName) => {
  let timeData = []; // Declare the timeData array locally

  try {
    const promises = AllActivity.map(async (element) => {
      const response = await api.get('/FetchPostedTime.php/', {
        params: {
          JsonUserName: userName,
          JsonActivity: element,
          JsonNow: thisDay
        },
      });
      return response.data;
    });
    const results = await Promise.all(promises);
    timeData = results; // Update the timeData with the fetched results
  } catch (error) {
    console.error('Error fetching data', error);
  }

  return timeData; // Return the updated timeData
};

export const fetchTotalTimeDiscipline = async (userName, endDate) => { //overall
  let totalDiscipline = '';
  try {
    const response = await api.get('/GetTimeDiscipline.php/get', {
      params: {
        JsonDays: '2023-02-25', //first day of database report in herba life app
        JsonUserName: userName,
        JsonNow: endDate
      },
    });
    totalDiscipline = response.data;
  } catch (error) {
    console.error('Error fetching data', error);
  }
  return totalDiscipline;
}

export const fetchPastDaysTimeDiscipline = async (userName, SelectedDate, endDate) => { //today,yesterday,...
  let fewDaysDiscipline = '';
  try {
    const response = await api.get('/GetTimeDiscipline.php/get', {
      params: {
        JsonDays: SelectedDate,
        JsonUserName: userName,
        JsonNow: endDate
      },
    });
    
    fewDaysDiscipline = response.data;
  } catch (error) {
    console.error('Error fetching data', error);
  }
  return fewDaysDiscipline;
}

export async function fetchTimeDisciplineBar(userName, startDate, endDate){
  let totalDiscipline = [];
  
  try {
    const response = await api.get('/GetTimeDisciplineBar.php/get', {
      params: {
        JsonDays: startDate, //first day of database report in herba life app
        JsonUserName: userName,
        JsonNow: endDate
      },
    });
    totalDiscipline = response.data;
  } catch (error) {
    console.error('Error fetching data', error);
  }
  return totalDiscipline;
}

//linegraph
export async function fetchLineGraph(userName, activityName,startDate,endDate){
  //console.log(endDate)
    try {
      const response = await api.get('/GetLineGraphTest.php/get', {
        params: {
         //first day of database report in herba life app
          JsonStartDate: startDate,
          JsonEndDate: endDate,
          JsonEntryUser: userName,
          JsonActivityName: activityName
        },
      });
      return response.data;
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
    
  }




export const fetchTotalMeasurement = async (userName, ActivityName, endDate) => {
  let measurement = '';
  try {
    const response = await api.get(`/GetTotalMeasurement.php`,{
      params:{
          JsonUserName: userName,
          JsonPhysicalActivity: ActivityName,
          JsonDate: endDate
      },
    });
    measurement = response.data;
    return measurement;
  } catch (error) {
    console.error('Error fetching data', error);
  }
}

export const fetchWorkoutKcals = async (userName, SelectedDate, endDate) => {
  let TotalWorkoutMeasurement = [];
  try {
    const response = await api.get('/FetchWorkoutKcal.php/?', {
      params: {
        JsonDate: SelectedDate,
        JsonUserName: userName,
        JsonNow: endDate
      },
    });
    TotalWorkoutMeasurement = response.data;
  } catch (error) {
    console.error('Error fetching data', error);
  }
  return TotalWorkoutMeasurement;
}

export const fetchTotalWorkoutKcals = async (userName, SelectedDate, endDate) => {
  let TotalWorkoutMeasurement = [];
  try {
    const response = await api.get('/FetchWorkoutKcals.php/?', {
      params: {
        JsonDate: SelectedDate,
        JsonUserName: userName,
        JsonNow: endDate
      },
    });
    TotalWorkoutMeasurement = response.data;
  } catch (error) {
    console.error('Error fetching data', error);
  }
  return TotalWorkoutMeasurement;
}

export const fetchCoachName = async(userName) => {
  let CoachName = '';
  try{
    const response = await api.get('/GetCoachName.php/get',{
      params: {
        JsonCoCoachName: userName
      },
    });
    CoachName = response.data;
  } catch (error){
    console.error('Error Fetching Coach Name');
  }
  return CoachName
}

export const fetchCoCoachName = async(userName) => {
  let CoCoachName = '';
  try{
    const response = await api.get('/GetCoCoachName.php/get',{
      params: {
        JsonCoachName: userName
      },
    });
    CoCoachName = response.data;
  } catch (error){
    console.error('Error Fetching Coach Name');
  }
  return CoCoachName
}

export const fetchTeamDiscipline = async ( startDate, endDate) => {

  let userName = localStorage.getItem('user')
  try {
    let teamMembers = await fetchCoachTeamMembers(userName);
    
    let totalTeamDiscipline = 0;
    let Coach = await fetchCoachName(userName)
    let CoCoach = await fetchCoCoachName(userName)
    if (Coach === null){
      teamMembers.push(CoCoach);
    } else if(CoCoach === null){
      teamMembers.push(Coach);
    }
    for (const member of teamMembers) {
      const discipline = await fetchPastDaysTimeDiscipline(member, startDate, endDate);
      totalTeamDiscipline += parseFloat(discipline);
    }

    const averageTeamDiscipline = totalTeamDiscipline / teamMembers.length;
    return averageTeamDiscipline.toFixed(1) + "%"; // Return average discipline as a formatted string
  } catch (error) {
    console.error('Error fetching team discipline', error);
    return "Error";
  }
};

export const isUserProfileAvailable = async(userName, column) => {
  let userNameStatus = ''
  try{
  const response = await api.get('/IsProfileFound.php/get',{
    params:{
      JsonUserName: userName
    }
  })
  userNameStatus = response.data
}catch(error){
  console.error(error)
}
  return userNameStatus
}

export const fetchDiseaseList = async () => {
  let Diseases = []; // Declare the teamMember array locally

  try {
    const response = await api.get('/GetDiseaseList.php');
    const results = await Promise.all(response.data);
    Diseases = [...new Set(results.flat())]; // Remove duplicates using Set and flat the array
  } catch (error) {
    console.error('Error fetching diseases: ', error);
  }

  return Diseases; // Return the updated teamMember
};

export const fetchNutritionList = async () => {
  let Nutritions = []; // Declare the teamMember array locally

  try {
    const response = await api.get('/GetNutritionsList.php');
    const results = await Promise.all(response.data);
    Nutritions = [...new Set(results.flat())]; // Remove duplicates using Set and flat the array
  } catch (error) {
    console.error('Error fetching data', error);
  }

  return Nutritions; // Return the updated teamMember
};

export const fetchWeightBasedOnDate = async (userName) => {
  let Weight = '';

  try {
    const response = await api.get('/GetWeightData.php',{
      params:{
        JsonUserName: userName
      }
    });
    Weight = response.data
  } catch (error) {
    console.error("Error Fetching data", error);
  }

  return Weight;
}

export const fetchWeightBasedOnCoach = async (userName) => {
  let Weight = '';

  try {
    const response = await api.get('/FetchNonCoachUser.php',{
      params:{
        JsonUserName: userName
      }
    });
    Weight = response.data
  } catch (error) {
    console.error("Error Fetching data", error);
  }

  return Weight;
}

export const fetchUserCoachName = async (userName) => {
  let CoachName = '';

  try {
    const response = await api.get('/GetUserCoachName.php',{
      params:{
        JsonUserName: userName
      }
    });
    CoachName = response.data
  } catch (error) {
    console.error("Error Fetching data", error);
  }

  return CoachName;
}

// Function to fetch user status based on username stored in localStorage
export const fetchUserStatus = async () => {
  try {
    // Retrieve the username from localStorage
    const username = localStorage.getItem('user');
    
   
    const response = await api.get('/GetUserStatus.php', {
      params: {
        JsonUserName: username
      }
    });
    return response.data; // Assuming status is returned from API
  } catch (error) {
    console.error('Error fetching user status:', error);
    throw error; // Rethrow the error for handling in the caller function
  }
}

export const handleCheckInactive = async () => {
  try {
    const userName = localStorage.getItem('user');
    const response = await api.get('/CheckAndUpdateUserStatus.php', {
      params: {
        JsonUserName: userName
      }
    });
    return response.data; // Assuming the PHP script returns the user status
  } catch (error) {
    console.error('Error checking user inactive status:', error);
    throw error; // Rethrow the error for handling in the caller function
  }
};

export const fetchAvgPostedTime = async (userName, activityName , selectedDate, endDate) => {
  try {
    const response = await api.get('/GetAverageTimeDiscipline.php', {
      params: {
        JsonUserName: userName,
        JsonActivityName: activityName,
        JsonDate: selectedDate, //from date
        JsonNow: endDate //current date
      }
    });
    return response.data; // Assuming the PHP script returns the average posted time
  } catch (error) {
    console.error('Error fetching average posted time:', error);
    throw error; // Rethrow the error for handling in the caller function
  }
};


export const fetchTeamMembersOnly =  async (userName) => {
  try {
    const response = await api.get('/GetTeamMembersOnly.php',{
      params:{
        JsonUserName: userName
      }
    });
    return response.data +1
  } catch (error) {
    console.error("Error Fetching data", error);
  }
}

export const changeUserStatus = async (userName) => {
  try {
    const response = await api.get('/ChangeUserStatus.php', {
      params: {
        JsonUserName: userName
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error changing user status:", error);
  }
}

export const changeUserApproval = async (userName) => {
  try {
    const response = await api.get('/ChangeUserApproval.php', {
      params: {
        JsonUserName: userName
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error changing user status:", error);
  }
}

export async function fetchIndividualDisciplinePercentage(userName, startDate, endDate, activity){

  try {

    const response = await api.get('/FetchIndividualDisciplineData.php/get', {
      params: {
        JsonDays: startDate, //first day of database report in herba life app
        JsonUserName: userName,
        JsonNow: endDate,
        Activity: activity
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
  }
  
}

export const AddNewUsers = async (userData, entryUser) => {
  console.log(entryUser)
  try {
    const response = await api.get('/InsertNewUser.php', {
      params: {
          ...userData,
          enterUser: entryUser
      }
  });
      
      console.log(response)
      return response.data;
  } catch (error) {
      throw new Error(error.response.data.message);
  }
};

export default FetchAllDetailsAPI;